.header {
  font-size: 0.8em;
  margin: 0;
  color: grey;
}

.badge-grey {
  border: 1.5px solid gainsboro !important;
}

.widget-container {
  margin-top: -2.1em;
}

.green-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: green;
  border-radius: 50%;
  margin-right: 5px;
}

.red-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
}

.underline {
  border-bottom: 1.5px rgb(221, 221, 221) solid;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.border-left-light-blue {
  border-left: 0.25rem solid #33aad6 !important;
}

.border-left-red {
  border-left: 0.25rem solid #de4e58 !important;
}

.border-left-purple {
  border-left: 0.25rem solid #e729ee !important;
}

.border-left-green {
  border-left: 0.25rem solid #64d957 !important;
}

.border-top-primary {
  border-top: 0.25rem solid #4e73df !important;
}

.gray-bg {
  background-color: whitesmoke;
}

.primary-links {
  color: #4e73df;
}

.accordion:active {
  background-color: whitesmoke !important;
}

.radio-custom {
  font-size: 1.3em !important;
}

.cursor-pointer {
  cursor: pointer;
}

hr.solid {
  border-top: 1px solid #bbb;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
