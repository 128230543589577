@import '../../../../shared/design-system/styles/template/variables';
@import '../../../../shared/design-system/styles/colors';

.project-row {
  td {
    background-color: rgba($links-teal, 0.1) !important;
  }

  &.orange {
    td {
      background-color: rgba($orange, 0.1) !important;
    }
  }
}

.test-table {
  td.container-dashboard > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  td.container-dashboard {
    height: 35px !important;
    max-height: 35px !important;
  }

  .project-name {
    height: 32px;
    display: block;
    line-height: 16px;
    align-content: center;
  }
}
