@import 'bootstrap';
@import '../../styles/template/variables';

.app-sidebar {
  transition: all 0.2s;

  .sidebar-brand {
    display: flex;
    align-items: center;
    justify-content: center;

    @mixin logo-properties($height, $top, $left) {
      height: $height;
      position: absolute;
      top: $top;
      left: $left;
    }

    .logo {
      @include logo-properties(40px, 20px, 20px);
      // transition: all 0.3s;
    }

    .logo-pittogramma {
      @include logo-properties(35px, 20px, 20px);
      margin-left: -0.3em;
      transition: all 0.3s;
      z-index: -999;
    }
  }

  .nav-link {
    span {
      margin-left: 8px;

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }

  &.toggled {
    .nav-link {
      span {
        margin-left: 0;
      }
    }
  }
}

.logo-links {
  font-family: Continuum;
  font-size: 48px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}

.sidebar-hr {
  margin: 0.5rem;
  color: $primary;
}

.link-item.disabled {
  color: grey !important;
}

.link-item.disabled i {
  color: grey !important;
}

// .sidebar-brand img {
//  transition: transform 0.2s ease;
// }

// .sidebar-brand img.logo {
//  transform: translateX(-100%);
// }

// .sidebar-brand img.logo-pittogramma {
//  transform: translateX(0);
// }

// .sidebar-brand img.logo {
//  transform: translateX(0);
// }
.badge-not {
  background-color: $gray-500;
  color: white;
  padding: 0.2em 0.4em;
  text-align: center;
  border-radius: 3px;
  font-size: 0.6em !important;
}

.rounded-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  overflow: hidden;
  background-color: #cccccc;
  width: 2em;
  height: 2em;

  span {
    color: $black;
    font-weight: 600;
    font-size: 1.2em;
  }
}

div.link-item.profile-item {
  background-color: #22519e;
}

.name-profile {
  color: white;
  font-size: 0.7em;
}

.logout-span {
  color: white;
  position: absolute;
  right: 0.7em;
  top: 1.2em;
}

.profile-nav:hover {
  background-color: transparent !important;
}

.opacity {
  opacity: 0.5;
}

.justify-navbar {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.justify-navbar-div {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}

.justify-toggled {
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.shadow-left {
  -webkit-box-shadow: -50px 0px 43px -30px rgba(0, 0, 0, 0.04);
  box-shadow: -50px 0px 43px -30px rgba(0, 0, 0, 0.04);
}

.notification-span {
  white-space: nowrap;
}

.hamburger {
  display: none;
  color: black;
}

@media (max-width: 767px) {
  .hamburger {
    display: block;
    right: 2em;
    z-index: 9999;
  }

  #basic-navbar-nav {
    background-color: white !important;
    padding: 1em;
    justify-content: center !important;
    border-radius: 12px;
    -webkit-box-shadow: -50px 0px 43px -30px rgba(0, 0, 0, 0.04);
    box-shadow: -50px 0px 43px -30px rgba(0, 0, 0, 0.04);
  }

  .hideOnSm {
    display: none;
  }
}

@media (max-width: 1300px) {
  .justify-toggled {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
}

.fixed-sidebar {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: hidden !important;
  z-index: 1000;
}

.toggled {
  width: 0;
}

.main-content {
  margin-left: 250px;
  transition: margin-left 0.3s ease;
}

.toggled .main-content {
  margin-left: 0;
}

.sidebar-link-label-container {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-bottom-section {
  position: absolute;
  bottom: 4px;
  left: 8px;
}
